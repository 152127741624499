document.querySelectorAll('a[href^="#"]:not(.panel-formation)').forEach((anchor) => {
	anchor.addEventListener("click", function (e) {
		e.preventDefault();
		const target = e.target.getAttribute("href");
		if (target == "#") return;
		const targetEL = document.querySelector(target);
		if (!targetEL) return;
		console.log(targetEL);
		// get target distance from top of the page without padding
		const targetTop = targetEL.getBoundingClientRect().top + window.scrollY;
		// get header height with padding
		const headerHeight = document.querySelector("header").getBoundingClientRect().height;
		// get target distance - header height

		const distance = targetTop - headerHeight;
		window.scrollTo({
			top: distance,
			left: 0,
			behavior: "smooth",
		});
	});
});
