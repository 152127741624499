import utils from "../utils";
const { fadeIn, fadeOut } = utils();
const popitContact = () => {
	const contactFormELs = document.querySelectorAll(".wpcf7-form");
	if (!contactFormELs) return;
	Array.from(contactFormELs).map((contactFormEl) => {
		const popitAcceptance = contactFormEl.querySelector(".popit.acceptance");
		if (!popitAcceptance) return;
		const form_submit = contactFormEl.querySelector(".form_submit");
		if (!form_submit) return;
		form_submit.addEventListener("mouseenter", () => {
			let btnSubmit = child.querySelector(".wpcf7-form-control.wpcf7-submit");
			if (btnSubmit.disabled == true) {
				popitAcceptance.style.opacity = 1;
				popitAcceptance.style.visibility = "visible";
			}
		});
		form_submit.addEventListener("mouseleave", () => {
			popitAcceptance.style = "";
		});
	});
};

document.addEventListener("DOMContentLoaded", popitContact());

// custom choose__paiement

const toggleActiveTab = (selectors, value, target) => {
	selectors.forEach((selector) => {
		selector === target ? selector.nextElementSibling.classList.add("radio-tabs__tab--active") : selector.nextElementSibling.classList.remove("radio-tabs__tab--active");
	});
	const displayGroups = document.querySelectorAll(".formpaiement");
	displayGroups.forEach((displayGroup) => {
		if (displayGroup.id == value) {
			fadeIn(displayGroup);
		} else {
			fadeOut(displayGroup);
		}
	});
};

document.addEventListener("DOMContentLoaded", function () {
	const selectors = document.querySelectorAll('input[name="choose_paiement"');
	if (!selectors) return;
	Array.from(selectors).map((selector) => {
		if (selector.checked) {
			const value = selector.value;
			toggleActiveTab(selectors, value, selector);
		}
		selector.addEventListener("change", function (e) {
			//alert("En changeant le mode paiement vous perdez les données déjà remplies");
			const target = e.target;
			const value = target.value;
			toggleActiveTab(selectors, value, target);
		});
	});
});

// forms controlling each other
document.addEventListener("DOMContentLoaded", function () {
	const formpaiement = document.querySelectorAll(".formpaiement");
	if (!formpaiement) return;
	Array.from(formpaiement).map((el) => {
		const form = el.querySelector(".wpcf7-form");
		if (!form) return;
		const inputs = form.querySelectorAll("input");
		inputs.forEach((input) => {
			input.addEventListener("change", function (e) {
				input.value = e.target.value;
			});
		});
	});
});

// handle form with floating paiement
document.addEventListener("DOMContentLoaded", () => {
	const formpaiement = document.querySelectorAll(".formpaiement");
	if (!formpaiement) return;
	Array.from(formpaiement).map((el) => {
		// variable price
		const qtyControl = el.querySelector(".qty-control");
		const floatingPaiement = el.querySelector(".floating-paiement");
		if (!qtyControl || !floatingPaiement) return;
		// set default total
		const qtyFront = floatingPaiement.querySelector(".price-qty");
		const totalFront = floatingPaiement.querySelector(".total");
		const priceByQty = floatingPaiement.querySelector(".price-by-qty").querySelector(".price").textContent;
		totalFront.textContent = parseFloat(qtyControl.value) * parseFloat(priceByQty);
		// add total to strip btn
		const cf7Stripe = el.querySelector(".wpcf7-stripe");
		if (cf7Stripe) {
			const buttons = cf7Stripe.querySelectorAll("button");
			Array.from(buttons).map((btn) => {
				if (!btn.contains(document.querySelector(".total-btn"))) {
					btn.insertAdjacentHTML("beforeend", `<span class="total-btn">(${parseFloat(qtyControl.value) * parseFloat(priceByQty)} €)</span>`);
				}
			});
		}
		qtyControl.addEventListener("change", (e) => {
			qtyFront.textContent = e.target.value;
			totalFront.textContent = parseFloat(e.target.value) * parseFloat(priceByQty);
			const totatBtn = el.querySelectorAll(".total-btn");
			if (totatBtn) {
				Array.from(totatBtn).map((total) => {
					total.textContent = `(${parseFloat(e.target.value) * parseFloat(priceByQty)} €)`;
				});
			}
		});
	});
});
