const getPopup = async (url) => {
	try {
		const response = await fetch(url);
		return response.json();
	} catch (e) {
		console.log(e);
	}
};

const handlePopupOpener = () => {
	// if template ID
	const openers = document.querySelectorAll("a[data-popup-tpl-id]");
	if (!openers) return;
	Array.from(openers).map((opener) => {
		opener.addEventListener("click", () => {
			const apiBase = `${window.location.href}/wp-json/wp/v2/m1popup/${opener.dataset.openPopup}`;
			getPopup(apiBase).then((reponse) => {
				console.log(reponse);
				document.querySelector("body").insertAdjacentHTML(
					"beforeend",
					`<div class="popup">
          <div class="popup__overlay"></div>
          <div class="popup__content">${reponse.content.rendered}</div>
        </div>`
				);
			});
		});
	});
	// if CSS ID
	const idsopeners = document.querySelectorAll("a[data-popup-id]");
	if (!idsopeners) return;
	Array.from(idsopeners).map((opener) => {
		opener.onclick = () => {
			console.log(opener);
			const el = document.getElementById(opener.dataset.popupId);
			el.classList.remove("popup--hidden");

			setTimeout(() => {
				el.classList.add("popup--transitionend");
			}, 300);

			el.querySelector(".popup__overlay").onclick = () => {
				el.classList.remove("popup--transitionend");
				setTimeout(() => {
					el.classList.add("popup--hidden");
				}, 300);
			};
		};
	});
};

handlePopupOpener();
