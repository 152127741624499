var timeout;

window.addEventListener(
	"scroll",
	function (event) {
		// If there's a timer, cancel it
		if (timeout) {
			window.cancelAnimationFrame(timeout);
		}

		// Setup the new requestAnimationFrame()
		timeout = window.requestAnimationFrame(function () {
			if (window.scrollY > 2) {
				if (!document.querySelector("header:not(.ghost)")) return;
				document.querySelector("header:not(.ghost)").classList.add("fixed");
				// add ghost header clone
				if (!document.querySelector("#wrapper-content .header.ghost")) {
					document.querySelector("#wrapper-content").insertAdjacentHTML("afterbegin", '<header class="header ghost"></header>');
					document.querySelector("#wrapper-content .header.ghost").style.height = document.querySelector("header.fixed").offsetHeight + "px";
				}
			}
			if (window.scrollY < 2) {
				if (!document.querySelector("header.fixed")) return;
				document.querySelector("header.fixed").classList.remove("fixed");
				// remove ghost header clone
				if (document.querySelector("#wrapper-content .header.ghost")) {
					document.querySelector("#wrapper-content .header.ghost").remove();
				}
			}
		});
	},
	false
);
