// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from "swiper";
// import Swiper and modules styles
import "swiper/css/bundle";
document.addEventListener("DOMContentLoaded", function () {
	if (document.getElementsByClassName("slider-element").length > 0) {
		let sliders = document.getElementsByClassName("slider-element");
		Array.prototype.forEach.call(sliders, function (slider) {
			// thumbnails
			var options = {};
			if (slider.parentElement && slider.parentElement.contains(document.querySelector(".swiper-thumbnail"))) {
				let thumbsSwiperEl = slider.parentElement.querySelector(".swiper-thumbnail");
				var thumbsSwiper = new Swiper(thumbsSwiperEl, {
					slidesPerView: thumbsSwiperEl.dataset.col,
					spaceBetween: 10,
				});

				options["thumbs"] = {
					swiper: thumbsSwiper,
				};
			}
			options["breakpoints"] = {};
			options["breakpoints"][640] = {};
			options["breakpoints"][900] = {};
			if (slider.dataset.spacebetween) {
				options["spaceBetween"] = parseInt(slider.dataset.spacebetween);
			}
			let slidesperview = JSON.parse(slider.dataset.slidesperview);
			if (slidesperview) {
				if (slidesperview.mob) {
					options["slidesPerView"] = slidesperview.mob;
				}
				if (slidesperview.tab) {
					options["breakpoints"]["640"]["slidesPerView"] = slidesperview.tab;
				}
				if (slidesperview.desk) {
					options["breakpoints"]["900"]["slidesPerView"] = slidesperview.desk;
				}
			}

			if (slider.dataset.arrows) {
				options["navigation"] = {
					nextEl: slider.nextElementSibling.nextElementSibling,
					prevEl: slider.nextElementSibling,
				};
			}
			if (slider.dataset.dots) {
				options["pagination"] = {
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true,
				};
			}
			if (slider.dataset.autoplay) {
				options["autoplay"] = {
					delay: 3000,
				};
			}

			options["watchOverflow"] = true;
			options["loop"] = slider.dataset.loop;
			options["modules"] = [Navigation, Pagination];

			if (options) {
				var swiper = new Swiper(slider, options);

				if (slider.dataset.lightbox) {
					new SimpleLightbox('.slider-element[data-lightbox="true"] a', {});
					swiper.on("slideChange", function () {
						console.log("slide changed");
						new SimpleLightbox('.slider-element[data-lightbox="true"] a', {});
					});
				}
			}
		});
	}
});
