function openNav() {
	document.querySelector("body").classList.toggle("menu-open");
}

function init() {
	/**
	 *
	 * MENU ITEM ARROW
	 *
	 ***********************/

	const menuItemArrows = document.querySelectorAll(".menu-item-link");
	for (const menuItemArrow of menuItemArrows) {
		let arrow = menuItemArrow.querySelector(".menu-item-arrow");
		if (arrow) {
			let isOpen = false;
			arrow.addEventListener("click", (e) => {
				console.log(isOpen);
				isOpen = !isOpen;
				const submenu = arrow.parentElement.nextElementSibling;
				if (isOpen) {
					submenu.style.height = submenu.scrollHeight + "px";
				} else {
					submenu.style.height = 0;
				}
			});
		}
	}
}
document.addEventListener("DOMContentLoaded", init());

// toggle hamburger
const handleNav = () => {
	const burgerToggle = () => {
		const toggleNodes = document.querySelectorAll(".menu-toggle");
		if (!toggleNodes) return;
		Array.from(toggleNodes).map((toggle) => {
			toggle.addEventListener("click", () => {
				document.querySelector("body").classList.toggle("menu-open");
			});
		});
	};

	burgerToggle();
};

document.addEventListener("DOMContentLoaded", handleNav());
