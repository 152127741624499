document.addEventListener("DOMContentLoaded", () => {
	let galleries = document.querySelectorAll(".single_img_lightbox");
	if (galleries.length > 0) {
		var smpl_lb = new SimpleLightbox(".single_img_lightbox a", {
			className: "single_img_lightbox",
		});
		Array.from(galleries).map((gallery) => {
			gallery.previousElementSibling.addEventListener("click", () => {
				smpl_lb.open();
			});
		});
	}

	// multiple galleries
	let multiple_galleries = document.querySelectorAll(".multiple_img_lightbox");
	if (multiple_galleries.length > 0) {
		var mtpl_lightbox = new SimpleLightbox(".multiple_img_lightbox a", {
			className: "multiple_img_lightbox",
		});
		Array.from(multiple_galleries).map((blop) => {
			blop.previousElementSibling.addEventListener("click", () => {
				console.log("test");
				mtpl_lightbox.open();
			});
		});
	}

	// gallery shortcode ligthbox
	let gallery_light = document.querySelectorAll(".gallery-with-lightbox a");
	if (gallery_light.length > 0) {
		new SimpleLightbox(".gallery-with-lightbox a");
	}
});
