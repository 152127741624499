import utils from "../utils"
const { createElementClass, addInsideEL } = utils()

const handleCollapse = (el, className) => {
	el.classList.toggle(className + "--collapsed")
	// click outside to close
	document.addEventListener("click", (e) => {
		if (el.contains(e.target)) return
		el.classList.add(className + "--collapsed")
	})
}

const customSelect = () => {
	// add form control on top
	const forms = document.querySelectorAll(".wpcf7-form")
	if (!forms) return
	Array.from(forms).map((form) => {
		const selectEls = form.querySelectorAll("select:not(.default-selected)")
		if (!selectEls) return
		Array.from(selectEls).map((selectEL) => {
			selectEL.style.display = "none"
			const options = selectEL.querySelectorAll("option")
			const wrapper = createElementClass("div", ["custom-select", "custom-select--collapsed"])
			const wrapperOPT = createElementClass("div", ["custom-select__options"])
			const wrapperValue = createElementClass("div", ["custom-select__value"])
			addInsideEL(wrapper, "i")
			Array.from(options).map((option, i) => {
				if (i === 0) {
					wrapperValue.innerHTML = option.text
				} else {
					const customOPT = createElementClass("div", ["custom-select__option"])
					customOPT.innerHTML = option.value
					customOPT.setAttribute("data-select", i)
					wrapperOPT.append(customOPT)

					customOPT.addEventListener("click", () => {
						options[customOPT.dataset.select].selected = true
						wrapperValue.innerHTML = customOPT.innerHTML
					})
				}
			})
			wrapper.append(wrapperValue)
			wrapper.append(wrapperOPT)
			selectEL.after(wrapper)
			// handle click on wrapper
			wrapper.addEventListener("click", () => handleCollapse(wrapper, "custom-select"))
		})
	})
}

const customSelectDefault = () => {
	const forms = document.querySelectorAll(".wpcf7-form")
	if (!forms) return
	Array.from(forms).map((form) => {
		const selectEls = form.querySelectorAll("select.default-selected")
		if (!selectEls) return
		Array.from(selectEls).map((selectEL) => {
			// wait for cf7 to fill the select
			setTimeout(() => {
				selectEL.style.display = "none"
			}, 500)
			const options = selectEL.querySelectorAll("option")
			const wrapper = createElementClass("div", ["custom-select", "custom-select--collapsed"])
			const wrapperOPT = createElementClass("div", ["custom-select__options"])
			const wrapperValue = createElementClass("div", ["custom-select__value"])
			addInsideEL(wrapper, "i")
			Array.from(options).map((option, i) => {
				const customOPT = createElementClass("div", ["custom-select__option"])
				customOPT.innerHTML = option.value
				customOPT.setAttribute("data-select", i)
				wrapperOPT.append(customOPT)

				const controled = form.querySelector(".qty-control.controled")

				// default selected
				if (option.selected) {
					wrapperValue.innerHTML = option.text
					if (controled) {
						controled.setAttribute("max", option.text === "Intra" ? 12 : 4)
					}
				}

				customOPT.addEventListener("click", () => {
					options[customOPT.dataset.select].selected = true
					wrapperValue.innerHTML = customOPT.innerHTML
					if (controled) {
						if (customOPT.innerHTML === "Inter" && controled.value > 4) {
							controled.value = 4
						}
						controled.setAttribute("max", customOPT.innerHTML === "Intra" ? 12 : 4)
					}
				})
			})
			wrapper.append(wrapperValue)
			wrapper.append(wrapperOPT)
			selectEL.after(wrapper)
			// handle click on wrapper
			wrapper.addEventListener("click", () => handleCollapse(wrapper, "custom-select"))
		})
	})
}

customSelect()
// custom select with default selected
customSelectDefault()

const customSelectFH = () => {
	// add form control on top
	const forms = document.querySelectorAll(".wpcf7-form")
	if (!forms) return
	Array.from(forms).map((form) => {
		const wrappers = form.querySelectorAll(".custom-select-cf7fillhidden")
		if (!wrappers) return
		Array.from(wrappers).map((wrapper) => {
			const control = form.querySelector(wrapper.dataset.control)
			const wrapperValue = wrapper.querySelector(".custom-select-cf7fillhidden__value")
			wrapper.addEventListener("click", () => handleCollapse(wrapper, "custom-select-cf7fillhidden"))
			const options = wrapper.querySelectorAll(".custom-select-cf7fillhidden__option")
			if (!options) return
			Array.from(options).map((option) => {
				option.addEventListener("click", () => {
					wrapperValue.innerHTML = option.innerHTML
					control.value = option.textContent.trim()
					console.log(control.value)
				})
			})
		})
	})
}
customSelectFH()
