// cards link
const handleCardsLink = () => {
	const cardsLink = document.querySelectorAll(".card--link");
	if (!cardsLink) return;
	Array.from(cardsLink).map((card) =>
		card.addEventListener("click", () => {
			const linkEl = card.querySelector("a");
			if (!linkEl) return;
			const href = linkEl.getAttribute("href");
			window.location = href;
		})
	);
};
handleCardsLink();

// panel formation

const togglePanel = (panel, isOpen) => {
	const body = document.querySelector("body");
	if (!isOpen) {
		body.classList.remove("panel-open");
		panel.classList.remove("fade-in");
		setTimeout(() => {
			panel.classList.remove("open");
		}, 300);
	} else {
		body.classList.add("panel-open");
		panel.classList.add("open");
		setTimeout(() => {
			panel.classList.add("fade-in");
		}, 300);
	}
};

const handlePanelFormation = () => {
	let isOpen = false;
	const openers = document.querySelectorAll(".open-panel-formation");
	if (!openers) return;
	Array.from(openers).map((opener) => {
		console.log(opener);
		const panel = document.querySelector('[data-id="' + opener.dataset.target + '"]');
		if (!panel) return;
		opener.addEventListener("click", () => {
			isOpen = !isOpen;
			togglePanel(panel, isOpen);
		});

		panel.querySelector(".panel-formation__overlay").addEventListener("click", (e) => {
			isOpen = !isOpen;
			togglePanel(panel, isOpen);
		});
	});
};

handlePanelFormation();

// handScrollPanel

// NUMBER ANIMATED

var a = 0;
jQuery(window).scroll(function () {
	if (!document.querySelector(".counternumber")) return;
	var oTop = jQuery(".counternumber").offset().top - window.innerHeight;
	if (a == 0 && jQuery(window).scrollTop() > oTop) {
		jQuery(".counter-value").each(function () {
			var $this = jQuery(this),
				countTo = $this.attr("data-count");
			jQuery({
				countNum: $this.text(),
			}).animate(
				{
					countNum: countTo,
				},

				{
					duration: 5000,
					easing: "swing",
					step: function () {
						$this.text(Math.floor(this.countNum));
					},
					complete: function () {
						$this.text(this.countNum);
						//alert('finished');
					},
				}
			);
		});
		a = 1;
	}
});

// print formation sheet
document.addEventListener("DOMContentLoaded", function () {
	// Query the element
	const printBtn = document.querySelector("[data-print]");
	if (!printBtn) return;
	printBtn.addEventListener("click", function () {
		// Create a fake iframe
		const iframe = document.createElement("iframe");

		// Make it hidden
		iframe.style.height = 0;
		iframe.style.visibility = "hidden";
		iframe.style.width = 0;

		// Set the iframe's source
		iframe.setAttribute("srcdoc", "<html><body></body></html>");

		document.body.appendChild(iframe);

		iframe.contentWindow.addEventListener("afterprint", function () {
			iframe.parentNode.removeChild(iframe);
		});

		iframe.addEventListener("load", function () {
			// Clone the image
			const image = document.createElement("img");
			image.src = printBtn.dataset.print;
			image.style.maxWidth = "100%";

			// Append the image to the iframe's body
			const body = iframe.contentDocument.body;
			body.style.textAlign = "center";
			body.appendChild(image);

			image.addEventListener("load", function () {
				// Invoke the print when the image is ready
				iframe.contentWindow.print();
			});
		});
	});
});
