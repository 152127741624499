import "../../scss/04-composants/_tabs.scss";
const tabs = () => {
	let size = getComputedStyle(document.querySelector("body"), ":before").getPropertyValue("content");
	let tabs = document.querySelector(".tabs") || undefined;
	const condition = window.matchMedia("(max-width: 960px)").matches;

	if (tabs) {
		// activate default first child link
		let links = tabs.querySelectorAll("li");
		links[0].classList.add("active");
		tabs.querySelectorAll(".tab")[0].classList.remove("fade");
		Array.from(links).map((link) => {
			link.addEventListener("click", () => {
				let target = document.querySelector(`${link.dataset.target}`) || undefined;
				tabs.querySelector("li.active").classList.remove("active");
				link.classList.add("active");
				if (target) {
					if (!condition && !target.classList.contains("fade")) return;

					Array.from(tabs.querySelectorAll(".tab")).map((tab) => {
						// fade out not target
						if (tab != target && !tab.classList.contains("fade")) {
							tab.classList.add("fade-out");
							setTimeout(() => {
								tab.classList.add("fade");
								tab.classList.remove("fade-out");
							}, 500);
						}
					});
					// fade in target
					target.classList.add("fade-in");
					setTimeout(() => {
						target.classList.remove("fade-in");
						target.classList.remove("fade");
					}, 300);
					if (condition) {
						tabs.classList.add("activated");
					}
				}
			});
		});
	}
};

document.addEventListener("DOMContentLoaded", tabs());
