//import "../../scss/composants/_spoiler.scss";
if (document.querySelectorAll(".spoiler").length > 0) {
	var spoilers = document.querySelectorAll(".spoiler");
	spoilers.forEach((spoiler) => {
		var display = spoiler.querySelector(".spoiler-display");
		var hidden = spoiler.querySelector(".spoiler-hidden");
		var toggle = spoiler.classList.contains("spoiler--wrapped") ? spoiler : spoiler.querySelector(".spoiler-bouton");
		console.log(hidden);
		toggle.addEventListener("click", (e) => {
			hidden.classList.toggle("open");
			if (hidden.classList.contains("open")) {
				hidden.style.height = hidden.scrollHeight + "px";
				if (!toggle.classList.contains("spoiler--wrapped")) {
					toggle.innerHTML = toggle.dataset.close;
				}
			} else {
				hidden.style.height = 0;
				if (!toggle.classList.contains("spoiler--wrapped")) {
					toggle.innerHTML = toggle.dataset.open;
				}
			}
		});
	});
}
