var btt = document.getElementById("backtotop") || null;

btt &&
	btt.addEventListener("click", () => {
		window.scrollTo({
			behavior: "smooth",
			top: 0,
			left: 0,
		});
	});
